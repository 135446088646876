import React, { useState, useEffect } from "react";
import Checkmark from "../Asset/Images/Checkmark.png";
import Flowshit from "../Asset/Images/flowshit.gif";
import axios from "axios";
import LoaderComponet from "./LoderComponent";
import { useNavigate } from "react-router-dom";

function Screen8() {
  axios.defaults.withCredentials = true;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios.get(
        `https://www.ai-accelerator.io/dashboard`
      );
      console.log(response);
      if (response.data === "Success") {
        setLoading(false);
        navigate("/s8");
        console.log("Successded OK");
      } else {
        setLoading(false);
        navigate("/");
      }
    })();
  }, [navigate]);

  const accessShopifyStore = async () => {
    const response = await axios.get(
      `https://www.ai-accelerator.io/api/store/url`
    );
    if (response.data.shop) {
      setLoading(false);
      const newTab = window.open(
        `https://admin.shopify.com/store/${response.data.shop}`,
        "_blank"
      );
      newTab.focus();
    }
  };

  const discordBtn = () => {
    const newTab = window.open(`https://discord.gg/frVrPtAMGs`, "_blank");
    newTab.focus();
  };

  return (
    <div id="body">
      {/* {loading ? <LoaderComponet loading /> : null} */}
      <section className="c-main">
        <div className="c-cricle_imgs6">
          <img src={Flowshit} alt="" srcSet="" />
        </div>
        <div className="c-content_box c_screen_8box">
          <div className="c-content_boxh7">
            <h3>COMPLETE</h3>
          </div>
          <div className="c-content_boxscree-6">
            <img src={Checkmark} alt="" srcSet="" />
          </div>
          <div className="c-content_boxscree-btn">
            <button onClick={accessShopifyStore}>TAKE ME TO MY STORE</button>
          </div>
          <div className="c-content_boxh7">
            <h4
              onClick={discordBtn}
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
                color: "white",
                marginTop: "20px",
                marginBottom: "20px",
                alignItems: "center",
              }}
            >
              Join The Discord <i className="fa-solid fa-arrow-right"></i>
            </h4>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Screen8;
