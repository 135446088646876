import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Flowshit from "../Asset/Images/flowshit.gif";
import LoaderComponet from "./LoderComponent";
import axios from "axios";
import WistiaPlayer from "./wistiaEmbed";
import shopURL from "../Asset/Images/shopURL.png";
import apps from "../Asset/Images/apps.png";
import shopDropdown from "../Asset/Images/shopDropdown.png";
import apiScopes from "../Asset/Images/apiScopes.png";
import developApps from "../Asset/Images/developApps.png";
import appNaming from "../Asset/Images/appNaming.png";
import tokenDisplay from "../Asset/Images/tokenDisplay.png";
import allowCustom from "../Asset/Images/allowCustom.png";
import shopGif from "../Asset/Images/shopGif.gif";
import checkMark2 from "../Asset/Images/checkMark2.png";
import gif4 from "../Asset/Images/gif4.gif";

const ConfigureShopP2 = () => {
  axios.defaults.withCredentials = true;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [storeNameError, setStoreNameError] = useState("");
  const [accessKeyError, setAccessKeyError] = useState("");

  const [formData, setFormData] = useState({
    storeName: "",
    accessToken: "",
  });

  const handleStoreChange = (event) => {
    setFormData({
      ...formData,
      storeName: event.target.value,
    });
    setStoreNameError("");
  };

  const handleAccessTokenChange = (event) => {
    setFormData({
      ...formData,
      accessToken: event.target.value,
    });
    setAccessKeyError("");
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios.get(
        `https://www.ai-accelerator.io/dashboard`
      );
      console.log(response);
      if (response.data === "Success") {
        setLoading(false);
        navigate("/s5");
        console.log("Successded OK");
      } else {
        setLoading(false);
        navigate("/");
      }
    })();
  }, [navigate]);

  const nxt = () => {
    navigate("/s4");
  };

  const handleBtnClick = () => {
    redirectToAnotherUrl();
  };

  const redirectToAnotherUrl = () => {
    const newTab = window.open(
      "https://www.shopify.com/free-trial?irgwc=1&partner=3623097&affpt=excluded&utm_channel=affiliates&utm_source=3623097-impact&utm_medium=cpa",
      "_blank"
    );
    newTab.focus();
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   try {
  //     await axios.post("https://www.ai-accelerator.io/api/store", formData);
  //     await axios.post(
  //       "https://www.ai-accelerator.io/api/accesstoken",
  //       formData
  //     );

  //     setLoading(false);

  //     // success
  //     navigate("/s5");
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("Error:", error.message);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    navigate("/s6");
  };

  const handleOpenShopLink = () => {
    const newTab = window.open(
      `https://www.shopify.com/free-trial?irgwc=1&partner=3623097&affpt=excluded&utm_channel=affiliates&utm_source=3623097-impact&utm_medium=cpa`,
      "_blank"
    );
    newTab.focus();
  };

  function redirectToXYZ() {
    const newTab = window.open(
      `https://getstartedtiktok.pxf.io/vN0aLj`,
      "_blank"
    );
    newTab.focus();
  }

  return (
    <div className="c-screen_2">
      <div className="c-cricle_img">
        <img
          src={Flowshit}
          alt=""
          srcSet=""
          style={{ height: "140vh", width: "140vh" }}
        />
      </div>
      {/* {loading ? <LoaderComponet loading /> : null} */}
      <div className="c-niche">
        <div className="c-niche_box-title c-4c-niche_box-title">
          <h3 className="text-left">Making the AI Work</h3>
          <div className="row gx-0 gy-0">
            <div
              className="col"
              style={{ alignItems: "center", paddingLeft: "60px" }}
            >
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">1.</h4>
                <p>Click Configure Admin API Scopes</p>
              </div>
              <div className="c-niche_box-list" style={{ marginTop: "20px" }}>
                <h4 className="c-niche_box-list-hh">2.</h4>
                <p>
                  Select ALL Access Scope Options On This <br /> Screen, Scroll
                  To The Bottom To Ensure All <br /> Have Been Selected;
                  (Basically This Means <br />
                  Check EVERY BOX Shown In Shopify)
                </p>
              </div>
              <div className="c-niche_box-list" style={{ marginTop: "20px" }}>
                <h4 className="c-niche_box-list-hh">3.</h4>
                <p>
                  After Selecting All The Options, Click On The Save <br />{" "}
                  Button At The Top Of The Screen, If Necessary,
                  <br /> Scroll Up To The Top
                </p>
              </div>
              <div className="c-niche_box-list" style={{ marginTop: "20px" }}>
                <h4 className="c-niche_box-list-hh">4.</h4>
                <p>
                  Return To This Tab To Continue & Click <br />
                  The &#9989; Done Button To Continue
                </p>
              </div>
            </div>
            <div className="col justify-content-center text-center">
              <div className="c-niche_box-list">
                <img
                  src={gif4}
                  alt=""
                  style={{ width: "85%", marginLeft: "8%" }}
                />
              </div>
              <div className="c-content_boxscree-btn">
                <h4
                  style={{
                    marginTop: "15px",
                    marginBottom: "-10px",
                    fontSize: "20px",
                    fontWeight: "bold",
                    width: "600px",
                    color: "#f53131",
                    letterSpacing: "1.2px",
                    lineHeight: "22px",
                  }}
                >
                  ** Make Sure You Select All Access Scope Options. If Any Are
                  Left Unchecked, Your Store Build May Fail **
                </h4>
              </div>
              <div className="c-content_boxscree-btn-done-btn">
                <button onClick={handleSubmit} style={{ width: "85%" }}>
                  Done &#9989;
                </button>
              </div>
            </div>
            {/* <div className="col-md-3">
                <div className="c-niche_box-list">
                  <h4 className="c-niche_box-list-hh">1.</h4>
                  <p>
                    Go on Shopify {">"} Copy Store Name
                    {"(the highlighted part shown below)"}
                  </p>
                </div>
                <div className="c-niche_box-list">
                  <img src={shopURL} alt="" className="w-100" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="c-niche_box-list">
                  <h4 className="c-niche_box-list-hh">2.</h4>
                  <p>Paste Store Name Below</p>
                </div>
                <div className="c-niche_box_sc5">
                  <input
                    type="text"
                    name="store_name"
                    id="store"
                    value={formData.storeName}
                    onChange={handleStoreChange}
                    placeholder="Enter Store Name Here..."
                    className="w-100"
                    style={{ fontSize: "18px" }}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="c-niche_box-list">
                  <h4 className="c-niche_box-list-hh">3.</h4>
                  <p>On The Left Side Bar Select "Apps" Above Add Apps</p>
                </div>
                <div className="c-niche_box-list">
                  <img src={apps} alt="" className="w-100" />
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureShopP2;
