import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Flowshit from "../Asset/Images/flowshit.gif";
import LoaderComponet from "./LoderComponent";
import WistiaPlayer from "./wistiaEmbed";
import checkMark2 from "../Asset/Images/checkMark2.png";
import gif2 from "../Asset/Images/gif2.gif";

function Screen5() {
  const [loading, setLoading] = useState(false);

  axios.defaults.withCredentials = true;

  const [formData, setFormData] = useState({
    storeName: "",
    accessToken: "",
  });

  // const [storeNameData, setStoreNameData] = useState({
  //   storeName: "",
  // });
  // const [accessTokenData, setAccessTokenData] = useState({
  //   accessToken: "",
  // });

  const [storeNameError, setStoreNameError] = useState("");
  const [accessKeyError, setAccessKeyError] = useState("");

  const handleStoreChange = (event) => {
    setFormData({
      ...formData,
      storeName: event.target.value,
    });
    setStoreNameError("");
  };

  const handleAccessTokenChange = (event) => {
    setFormData({
      ...formData,
      accessToken: event.target.value,
    });
    setAccessKeyError("");
  };

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const response = await axios.get(
        `https://www.ai-accelerator.io/dashboard`
      );
      console.log(response);
      if (response.data === "Success") {
        navigate("/s4");
        console.log("Successded OK");
      } else {
        navigate("/");
      }
    })();
  }, [navigate]);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   try {
  //     await axios.post("https://www.ai-accelerator.io/api/store", formData);
  //     await axios.post(
  //       "https://www.ai-accelerator.io/api/accesstoken",
  //       formData
  //     );

  //     setLoading(false);

  //     // success
  //     navigate("/s5");
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("Error:", error.message);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    navigate("/s5");
  };

  return (
    <div className="c-screen_2">
      <div
        className="c-cricle_img"
        // style={{ backgroundImage: `url(${Flowshit}) `, width: '700px', height: '700px', backgroundSize: 'cover', }}
      >
        <img src={Flowshit} alt="" srcSet="" />
      </div>
      {loading ? <LoaderComponet loading /> : null}
      <div className="c-niche">
        {/* <div className="c-niche_nextbtn">
          <button onClick={handleSubmit}>
            Next
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        </div> */}
        {/* <div className="c-niche_video">
          <WistiaPlayer
            videoId="c7mo3b9co0"
            wrapper="wistia-player-container-3"
          ></WistiaPlayer>
        </div> */}
        <div className="c-niche_box-title">
          <h3>Setting Up The AI</h3>
          <div className="row m-0">
            <div className="col-md-6 justify-content-center text-center">
              <div className="c-niche_box-list">
                <img
                  src={gif2}
                  alt=""
                  style={{ width: "85%", marginLeft: "8%" }}
                />
              </div>
              <div className="c-content_boxscree-btn-done-btn">
                <button onClick={handleSubmit} style={{ width: "85%" }}>
                  Done &#9989;
                </button>
              </div>
              {/* <div className="c-niche_box_sc5">
                <input
                  type="text"
                  name="store_name"
                  id="store"
                  value={formData.storeName}
                  onChange={handleStoreChange}
                  placeholder="Enter Store Name Here..."
                />
                {storeNameError && (
                  <p style={{ color: "red" }}>{storeNameError}</p>
                )}
                <input
                  type="text"
                  name="accessToken"
                  id="accessToken"
                  value={formData.accessToken}
                  onChange={handleAccessTokenChange}
                  placeholder="Enter Access Token Here..."
                />
                {accessKeyError && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {accessKeyError}
                  </p>
                )}
              </div> */}
            </div>
            <div className="col-md-6">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">1.</h4>
                <p>
                  Click The Button Above Add Apps, A Dropdown Will Open, Select
                  App And Sales Channel Settings
                </p>
              </div>
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">2.</h4>
                <p>On The Shopify Screen, Click Allow Custom App Development</p>
              </div>
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">3.</h4>
                <p>
                  Click Again On Allow Custom App Development -{">"} Click
                  Create An App
                </p>
              </div>
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">4.</h4>
                <span style={{ display: "flex" }}>
                  <p>Fill Name With</p>
                  <p
                    style={{ textDecoration: "underline", fontWeight: "bold" }}
                  >
                    "AI-Accelerator"
                  </p>
                  <p>& Click Create App</p>
                </span>
              </div>
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">5.</h4>
                <p>Click The Done Button To Continue</p>
              </div>
            </div>
          </div>
          {/* <div className="row m-0">
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">1.</h4>
                <p>
                  Enter your store <br /> name
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">2.</h4>
                <p>
                  On the left side bar select <br />
                  apps above Add Apps
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">3.</h4>
                <p>From the dropdown select Apps and channel settings</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">4.</h4>
                <p>Select Develop apps -{`>`} Allow custom app development</p>
              </div>
            </div>
          </div>
          <div className="row m-0 pt-5 mt-5">
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">5.</h4>
                <p>
                  Click Create an app and enter ai-accelerator in the App Name
                  box then click Create
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">6.</h4>
                <p>Click Configure Admin API Scopes</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">7.</h4>
                <p>
                  Make sure every box is ticked and click save and install app
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">8.</h4>
                <p>
                  On the next page, click reveal token once and copy the token,
                  paste this into the next box on ai-accelerator
                </p>
              </div>
            </div>
          </div> */}
          {/* <div className="c-niche_box_sc5">
            <input
              type="text"
              name="store_name"
              id="store"
              value={formData.storeName}
              onChange={handleStoreChange}
              placeholder="Enter Store Name Here..."
            />
            {storeNameError && <p style={{ color: "red" }}>{storeNameError}</p>}
            <input
              type="text"
              name="accessToken"
              id="accessToken"
              value={formData.accessToken}
              onChange={handleAccessTokenChange}
              placeholder="Enter Access Token Here..."
            />
            {accessKeyError && (
              <p style={{ color: "red", textAlign: "center" }}>
                {accessKeyError}
              </p>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Screen5;
