import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Flowshit from "../Asset/Images/flowshit.gif";
import LoaderComponet from "./LoderComponent";
import axios from "axios";
import WistiaPlayer from "./wistiaEmbed";
import checkMark2 from "../Asset/Images/checkMark2.png";

function Screen7() {
  axios.defaults.withCredentials = true;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function redirectToXYZ() {
    const newTab = window.open(
      `https://getstartedtiktok.pxf.io/vN0aLj`,
      "_blank"
    );
    newTab.focus();
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios.get(
        `https://www.ai-accelerator.io/dashboard`
      );
      console.log(response);
      if (response.data === "Success") {
        setLoading(false);
        navigate("/s8");
        console.log("Successded OK");
      } else {
        setLoading(false);
        navigate("/");
      }
    })();
  }, [navigate]);

  const finishBtn = () => {
    navigate("/s8");
  };

  const discordBtn = () => {
    const newTab = window.open(`https://discord.gg/frVrPtAMGs`, "_blank");
    newTab.focus();
  };

  const accessShopifyStore = async () => {
    const response = await axios.get(
      `https://www.ai-accelerator.io/api/store/url`
    );
    if (response.data.shop) {
      setLoading(false);
      const newTab = window.open(
        `https://admin.shopify.com/store/${response.data.shop}`,
        "_blank"
      );
      newTab.focus();
    }
  };

  return (
    <div id="body">
      {/* {loading ? <LoaderComponet loading /> : null} */}

      <section className="c-main">
        <div className="c-cricle_imgs6 c-cricle_hss">
          <img src={Flowshit} alt="" srcSet="" />
        </div>
        <div className="c-content_box c-content7_box">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <div className="c-niche_nextbtn">
              <button onClick={finishBtn}>
                Finish
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div> */}
            {/* <div
              className="c-niche_nextbtn"
              style={{ marginBottom: "60px", marginTop: "0px" }}
            >
              <button onClick={accessShopifyStore}>
                Take me to my store
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
            <div> */}
            <div className="c-content_boxscree-btn-next-done-btn">
              <button
                onClick={accessShopifyStore}
                style={{ marginTop: "-70px" }}
              >
                Take Me To My Store &nbsp;
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
          {/* <div className="c-content_boxh7">
            <h3>COMPLETE</h3>
          </div> */}
          <div className="c-content_boxh3" style={{ marginTop: "5%" }}>
            <h3>Final Bonuses!</h3>
          </div>
          <div className="c-content_boxscree-6">
            <div className="c-niche_video">
              <WistiaPlayer
                videoId="x1yloy04vt"
                wrapper="wistia-player-container-4"
              ></WistiaPlayer>
            </div>
          </div>
          <div className="c-content_boxscree-btn" style={{ marginTop: "20px" }}>
            <button onClick={redirectToXYZ}>Tiktok Affiliate Link</button>
          </div>
          <div className="c-content_boxscree-btn">
            <h4
              onClick={discordBtn}
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
                color: "white",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              Join The Discord <i className="fa-solid fa-arrow-right"></i>
            </h4>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Screen7;
