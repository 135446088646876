import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Flowshit from "../Asset/Images/flowshit.gif";
import LoaderComponet from "./LoderComponent";
import WistiaPlayer from "./wistiaEmbed";
import checkMark2 from "../Asset/Images/checkMark2.png";
import gif3 from "../Asset/Images/gif3.gif";
import gif5 from "../Asset/Images/gif5.gif";

const AuthorizeShopApp = () => {
  const [loading, setLoading] = useState(false);

  axios.defaults.withCredentials = true;

  const [formData, setFormData] = useState({
    storeName: "",
    accessToken: "",
  });

  const [uniqueStore, setUniqueStore] = useState("");

  // const [storeNameData, setStoreNameData] = useState({
  //   storeName: "",
  // });
  // const [accessTokenData, setAccessTokenData] = useState({
  //   accessToken: "",
  // });

  const [storeNameError, setStoreNameError] = useState("");
  const [accessKeyError, setAccessKeyError] = useState("");

  const handleStoreChange = (event) => {
    setFormData({
      ...formData,
      storeName: event.target.value,
    });
    setStoreNameError("");
  };

  const extractUniqueIdentifier = (urlString) => {
    const url = new URL(urlString);
    const pathname = url.pathname;
    const parts = pathname.split("/");
    const storeIndex = parts.indexOf("store");

    if (storeIndex !== -1 && storeIndex < parts.length - 1) {
      return parts[storeIndex + 1];
    }

    return null;
  };

  const handleAccessTokenChange = (event) => {
    setFormData({
      ...formData,
      accessToken: event.target.value,
    });
    setAccessKeyError("");
  };

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const response = await axios.get(
        `https://www.ai-accelerator.io/dashboard`
      );
      console.log(response);
      if (response.data === "Success") {
        navigate("/s6");
        console.log("Successded OK");
      } else {
        navigate("/");
      }
    })();
  }, [navigate]);

  const handleSubmit = async (e) => {
    const uniqueStore = extractUniqueIdentifier(formData.storeName);

    if (!uniqueStore) {
      setStoreNameError("Invalid URL or format");
      return;
    }

    console.log(uniqueStore, formData.accessToken);

    try {
      await axios.post("https://www.ai-accelerator.io/api/store", {
        accessToken: formData.accessToken,
        storeName: uniqueStore,
      });
      await axios.post(
        "https://www.ai-accelerator.io/api/accesstoken",
        formData
      );

      setLoading(false);
      console.log("here");
      // success
      navigate("/s7");
    } catch (error) {
      setLoading(false);
      console.error("Error:", error.message);
      navigate("/s6");
    }
  };

  const smallerScreenStyles = {
    width: "110%",
    margin: "0px",
    /* adjust other styles for smaller screens */
  };

  return (
    <div className="c-screen_2">
      <div className="c-cricle_img">
        <img src={Flowshit} alt="" srcSet="" />
      </div>
      {loading ? <LoaderComponet loading /> : null}
      <div className="c-niche">
        <div className="c-niche_box-title" style={{ marginTop: "10px" }}>
          <div
            className="div"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <h3 style={{ marginBottom: "30px" }}>Putting It All Together...</h3>
            <div>
              <div className="c-content_boxscree-btn-next-done-btn">
                <button onClick={handleSubmit} style={{ marginTop: "-60px" }}>
                  Done &#9989;
                </button>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ marginBottom: "0px", marginBottom: "-10px" }}
          >
            <div
              className="col-md-6"
              style={{
                alignItems: "center",
                paddingLeft: "60px",
              }}
            >
              <div className="c-niche_box-list" style={{ marginBottom: "0px" }}>
                <h4
                  className="c-niche_box-list-hh"
                  style={{ marginLeft: "0%" }}
                >
                  1.
                </h4>
                <p>
                  Click Reveal Token Once & Copy The <br /> Token That Was
                  Revealed (Keep In Mind You <br /> Cannot Copy The Token More
                  Than Once)
                </p>
              </div>

              <div className="c-niche_box-list">
                <h4
                  className="c-niche_box-list-hh"
                  style={{ marginLeft: "0%" }}
                >
                  2.
                </h4>
                <p>Paste The Token In The Field Below</p>
              </div>

              <div className="c-niche_box_sc5">
                <input
                  type="text"
                  name="accessToken"
                  id="accessToken"
                  value={formData.accessToken}
                  style={{
                    width: "80%",
                    borderColor: "#C7C7C7",
                    marginLeft: "-20%",
                    marginTop: "-1%",
                  }}
                  onChange={handleAccessTokenChange}
                  placeholder="Enter Token Here..."
                />
                {accessKeyError && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {accessKeyError}
                  </p>
                )}
              </div>
            </div>

            <div className="col justify-content-center text-center">
              <div className="c-niche_box-list">
                <img
                  src={gif3}
                  alt=""
                  style={{
                    width: "73%",
                    height: "290px",
                    marginLeft: "5%",
                    marginTop: "-2%",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col justify-content-center text-center">
              <div className="c-niche_box-list">
                <img
                  src={gif5}
                  alt=""
                  style={{
                    width: "73%",
                    height: "290px",
                    marginLeft: "8%",
                    marginTop: "2%",
                  }}
                />
              </div>
            </div>
            <div
              className="col-md-6"
              style={{ width: "50%", marginTop: "28px" }}
            >
              <div className="c-niche_box-list" style={{ width: "90%" }}>
                <h4
                  className="c-niche_box-list-hh"
                  style={{ marginLeft: "5%" }}
                >
                  3.
                </h4>
                <p>
                  Finally, Select The Shopify URL From The <br /> Address Bar At
                  The Top & Copy The URL
                </p>
              </div>

              <div className="c-niche_box-list">
                <h4
                  className="c-niche_box-list-hh"
                  style={{ marginLeft: "4%" }}
                >
                  4.
                </h4>
                <p>Paste The URL In The Field Below & Click Done</p>
              </div>
              <div className="c-niche_box_sc5">
                <input
                  type="text"
                  name="store_name"
                  id="store"
                  value={formData.storeName}
                  onChange={handleStoreChange}
                  placeholder="Enter Store URL Here..."
                  style={{
                    width: "80%",
                    borderColor: "#C7C7C7",
                    marginLeft: "-12%",
                    marginTop: "-1%",
                    ...(window.innerWidth < 570 && smallerScreenStyles),
                  }}
                />
                {storeNameError && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {storeNameError}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorizeShopApp;
