import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Flowshit from "../Asset/Images/flowshit.gif";
import LoaderComponet from "./LoderComponent";
import axios from "axios";
import WistiaPlayer from "./wistiaEmbed";
import shopURL from "../Asset/Images/shopURL.png";
import apps from "../Asset/Images/apps.png";
import shopDropdown from "../Asset/Images/shopDropdown.png";
import apiScopes from "../Asset/Images/apiScopes.png";
import developApps from "../Asset/Images/developApps.png";
import appNaming from "../Asset/Images/appNaming.png";
import tokenDisplay from "../Asset/Images/tokenDisplay.png";
import allowCustom from "../Asset/Images/allowCustom.png";
import shopGif from "../Asset/Images/shopGif.gif";
import checkMark2 from "../Asset/Images/checkMark2.png";
import gif1 from "../Asset/Images/gif1.gif";

function Screen4() {
  axios.defaults.withCredentials = true;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [storeNameError, setStoreNameError] = useState("");
  const [accessKeyError, setAccessKeyError] = useState("");

  const [formData, setFormData] = useState({
    storeName: "",
    accessToken: "",
  });

  const handleStoreChange = (event) => {
    setFormData({
      ...formData,
      storeName: event.target.value,
    });
    setStoreNameError("");
  };

  const handleAccessTokenChange = (event) => {
    setFormData({
      ...formData,
      accessToken: event.target.value,
    });
    setAccessKeyError("");
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios.get(
        `https://www.ai-accelerator.io/dashboard`
      );
      console.log(response);
      if (response.data === "Success") {
        setLoading(false);
        navigate("/s3");
        console.log("Successded OK");
      } else {
        setLoading(false);
        navigate("/");
      }
    })();
  }, [navigate]);

  const nxt = () => {
    navigate("/s4");
  };

  const handleBtnClick = () => {
    redirectToAnotherUrl();
  };

  const redirectToAnotherUrl = () => {
    const newTab = window.open(
      "https://www.shopify.com/free-trial?irgwc=1&partner=3623097&affpt=excluded&utm_channel=affiliates&utm_source=3623097-impact&utm_medium=cpa",
      "_blank"
    );
    newTab.focus();
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   try {
  //     await axios.post("https://www.ai-accelerator.io/api/store", formData);
  //     await axios.post(
  //       "https://www.ai-accelerator.io/api/accesstoken",
  //       formData
  //     );

  //     setLoading(false);

  //     // success
  //     navigate("/s5");
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("Error:", error.message);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    navigate("/s4");
  };

  const handleOpenShopLink = () => {
    const newTab = window.open(
      `https://www.shopify.com/free-trial?irgwc=1&partner=3623097&affpt=excluded&utm_channel=affiliates&utm_source=3623097-impact&utm_medium=cpa`,
      "_blank"
    );
    newTab.focus();
  };

  function redirectToXYZ() {
    const newTab = window.open(
      `https://getstartedtiktok.pxf.io/vN0aLj`,
      "_blank"
    );
    newTab.focus();
  }

  return (
    <div className="c-screen_2">
      {/* <div className="c-cricle_img">
        <img
          src={Flowshit}
          alt=""
          srcSet=""
          style={{ height: "140vh", width: "140vh" }}
        />
      </div>
      {loading ? <LoaderComponet loading /> : null} 
      <div className="c-niche">
        <div className="c-niche_nextbtn">
          <button onClick={handleSubmit}>
            Next
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        </div>
        <div
          className="c-niche_box-title c-4c-niche_box-title"
          style={{ marginTop: "-50px" }}
        >
          <h3 className="text-left">Next Steps:</h3>
          <div className="row gx-0 gy-0">
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">1.</h4>
                <p>
                  Go on Shopify {">"} Copy Store Name
                  {"(the highlighted part shown below)"}
                </p>
              </div>
              <div className="c-niche_box-list">
                <img src={shopURL} alt="" className="w-100" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">2.</h4>
                <p>Paste Store Name Below</p>
              </div>
              <div className="c-niche_box_sc5">
                <input
                  type="text"
                  name="store_name"
                  id="store"
                  value={formData.storeName}
                  onChange={handleStoreChange}
                  placeholder="Enter Store Name Here..."
                  className="w-100"
                  style={{ fontSize: "18px" }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">3.</h4>
                <p>On The Left Side Bar Select "Apps" Above Add Apps</p>
              </div>
              <div className="c-niche_box-list">
                <img src={apps} alt="" className="w-100" />
              </div>
            </div>
          </div>
          <hr
            style={{
              width: "75%",
              margin: "auto",
              marginTop: "50px",
              marginBottom: "20px",
              height: "2px",
              background: "white",
            }}
          />
          <div className="row gx-0 gy-0">
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">4.</h4>
                <p>From The Dropdown Select Apps and Channel Settings</p>
              </div>
              <div className="c-niche_box-list">
                <img src={shopDropdown} alt="" className="w-100" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">5.</h4>
                <p>Select Develop Apps -{">"} Allow Custom App Development</p>
              </div>
              <div className="c-niche_box-list">
                <img src={developApps} alt="" className="w-100" />
              </div>
              <div className="c-niche_box-list">
                <img src={allowCustom} alt="" className="w-100" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh ">6.</h4>
                <p>
                  Click Create An App And Enter Ai-Accelerator in The App Name
                  Box Then Click Create
                </p>
              </div>
              <div className="c-niche_box-list">
                <img src={appNaming} alt="" className="w-100" />
              </div>
            </div>
          </div>
          <hr
            style={{
              width: "75%",
              margin: "auto",
              marginTop: "50px",
              marginBottom: "20px",
              textAlign: "right",
              height: "2px",
              background: "white",
            }}
          />
          <div className="row gx-0 gy-0">
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">7.</h4>
                <p>
                  Click Configure Admin API Scopes & Check ALL Boxes, Then Click
                  Save & Install
                </p>
              </div>
              <div className="c-niche_box-list">
                <img src={shopGif} alt="" className="w-100" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">8.</h4>
                <p>Click Reveal Token Once And Copy The Token</p>
              </div>
              <div className="c-niche_box-list">
                <img src={tokenDisplay} alt="" className="w-100" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">9.</h4>
                <p>Paste The Token Below & Hit Next</p>
              </div>
              <div className="c-niche_box_sc5">
                <input
                  type="text"
                  name="accessToken"
                  id="accessToken"
                  value={formData.accessToken}
                  onChange={handleAccessTokenChange}
                  placeholder="Enter Access Token Here..."
                  className="w-100"
                  style={{ fontSize: "18px" }}
                />
                {accessKeyError && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {accessKeyError}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="row"></div>
          <div className="row"></div>
          <div className="row"></div>
        </div> 
                </div>*/}

      <div className="c-cricle_img">
        <img
          src={Flowshit}
          alt=""
          srcSet=""
          style={{ height: "140vh", width: "140vh" }}
        />
      </div>
      {/* {loading ? <LoaderComponet loading /> : null} */}
      <div className="c-niche">
        <div className="c-niche_box-title c-4c-niche_box-title">
          <h3 className="text-left">Create Your Shopify Store</h3>
          <div className="row gx-0 gy-0">
            <div
              className="col"
              style={{ alignItems: "center", paddingLeft: "60px" }}
            >
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">1.</h4>
                <p>
                  Click The Button Below "Create Store",
                  <br /> The Shopify Website Will Open In A New
                  <br /> Tab In Your Browser
                </p>
              </div>
              <div className="c-content_boxscree-btn">
                <button
                  onClick={handleOpenShopLink}
                  style={{
                    marginRight: "30%",
                    marginLeft: "10%",
                    marginTop: "2%",
                    fontSize: "2vw",
                    width: "100%",
                  }}
                >
                  Create Your Store
                </button>
              </div>
              <div className="c-niche_box-list" style={{ marginTop: "20px" }}>
                <h4 className="c-niche_box-list-hh">2.</h4>
                <p>Fill In The Field With Your Best Email</p>
              </div>
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">3.</h4>
                <p>Complete Registration Information</p>
              </div>
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">4.</h4>
                <p>Return To This Tab To Continue</p>
              </div>
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">5.</h4>
                <p>Click The "Done &#9989;" Button To Continue</p>
              </div>
            </div>
            <div className="col justify-content-center text-center">
              <div className="c-niche_box-list">
                <img
                  src={gif1}
                  alt=""
                  style={{ width: "85%", marginLeft: "7%" }}
                />
              </div>
              <div className="c-content_boxscree-btn-done-btn">
                <button onClick={handleSubmit} style={{ width: "85%" }}>
                  Done &#9989;
                </button>
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">1.</h4>
                <p>
                  Go on Shopify {">"} Copy Store Name
                  {"(the highlighted part shown below)"}
                </p>
              </div>
              <div className="c-niche_box-list">
                <img src={shopURL} alt="" className="w-100" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">2.</h4>
                <p>Paste Store Name Below</p>
              </div>
              <div className="c-niche_box_sc5">
                <input
                  type="text"
                  name="store_name"
                  id="store"
                  value={formData.storeName}
                  onChange={handleStoreChange}
                  placeholder="Enter Store Name Here..."
                  className="w-100"
                  style={{ fontSize: "18px" }}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="c-niche_box-list">
                <h4 className="c-niche_box-list-hh">3.</h4>
                <p>On The Left Side Bar Select "Apps" Above Add Apps</p>
              </div>
              <div className="c-niche_box-list">
                <img src={apps} alt="" className="w-100" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Screen4;
