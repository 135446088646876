import React, { useState, useEffect } from "react";
import Pets from "../Asset/Images/Pets.png";
import Home from "../Asset/Images/home.png";
import Flowshit from "../Asset/Images/flowshit.gif";
import Dumbbell from "../Asset/Images/Dumbbell.png";
import Workstation from "../Asset/Images/Workstation.png";
import Spa from "../Asset/Images/Spa-Flower.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoaderComponet from "./LoderComponent";

function Screen2() {
  axios.defaults.withCredentials = true;

  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    selectedItem: "",
  });

  useEffect(() => {
    (async () => {
      const response = await axios.get(
        `https://www.ai-accelerator.io/dashboard`,
        { withCredentials: true }
      );
      console.log(response);
      if (response.data === "Success") {
        navigate("/s2");
        console.log("Successded OK");
      } else {
        navigate("/");
      }
    })();
  }, [navigate]);

  const niches = [
    { image: Pets, label: "Pets", value: "Pets" },
    { image: Home, label: "Home/Office", value: "Home" },
    { image: Dumbbell, label: "Gym/Sports", value: "Fitness" },
    { image: Workstation, label: "Technology", value: "Tech" },
    { image: Spa, label: "Beauty", value: "Beauty" },
  ];

  const handleItemClick = (item) => {
    setFormData((prevState) => ({ ...prevState, selectedItem: item }));
    console.log(item);
  };

  const [themeError, setThemeError] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.selectedItem) {
      setThemeError("Please select a theme.");
      return;
    }
    setLoading(true);
    axios
      .post("https://www.ai-accelerator.io/api/themes", formData)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          navigate("/s3");
        }

        setResponseMessage("Data saved successfully");
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        navigate("/s2");
        setResponseMessage("Error saving data");
      });
  };

  return (
    <div className="c-screen_2">
      <div
        className="c-cricle_img"
        // style={{ backgroundImage: `url(${Flowshit}) `, width: '700px', height: '700px', backgroundSize: 'cover', }}
      >
        <img src={Flowshit} alt="" srcSet="" />
      </div>
      {loading ? <LoaderComponet loading /> : null}
      <div className="c-niche">
        <div className="c-niche_nextbtn">
          <button onClick={handleSubmit}>
            Next<i className="fa-solid fa-arrow-right"></i>
          </button>
        </div>

        <div className="c-niche_box-title" style={{ marginTop: "20vh" }}>
          <h3>Select your niche:</h3>
          {themeError && (
            <p style={{ color: "red", textAlign: "center" }}>{themeError}</p>
          )}
          <div className="second-scren row ">
            {niches.map((niche, index) => (
              <div className="col-lg" key={index}>
                <div
                  className={`c-niche_box-main ${
                    formData.selectedItem === niche.value
                      ? "selected-niche"
                      : ""
                  }`}
                  onClick={() => handleItemClick(niche.value)}
                >
                  <div className="c-niche_box-item">
                    <img src={niche.image} alt={niche.label} />
                  </div>
                  <div className="c-niche_box-link">
                    <p>{niche.label}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {responseMessage}
    </div>
  );
}

export default Screen2;
